import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../templates/layout';
import { NotFoundPageQuery } from '../types/graphql';

import '../styles/pages/not-found.scss';

interface NotFoundPageProps {
  data: {
    defaultHeader: NotFoundPageQuery['defaultHeader'];
    defaultFooter: NotFoundPageQuery['defaultFooter'];
  };
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => (
  <Layout
    alternateLanguages={[]}
    footerData={data.defaultFooter}
    headerData={data.defaultHeader}
  >
    <section className="not-found">
      <h1>NOT FOUND</h1>
      <p>It seems like the page you're looking for doesn't exist here. </p>
    </section>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage {
    defaultHeader: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: "en" } }
    ) {
      ...Header
    }
    defaultFooter: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: "en" } }
    ) {
      ...Footer
    }
    defaultCookieConsent: markdownRemark(
      fields: { collection: { eq: "cookie-consent" }, lang: { eq: "en" } }
    ) {
      ...CookieConsent
    }
  }
`;
